import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { StaticQuery, graphql, Link } from "gatsby"

const FeaturedResources = () => (
  <StaticQuery
    query={graphql`
      {
        allGraphCmsResource(limit: 4, sort: { fields: date, order: DESC }) {
          edges {
            node {
              id
              title
              slug
              date
              featuredImage {
                gatsbyImageData(
                  placeholder: BLURRED
                  layout: FULL_WIDTH
                  aspectRatio: 2
                )
              }
            }
          }
        }
      }
    `}
    render={data => (
      <div className="w-full h-full pt-24 hidden">
        <div className=" flex flex-col">
          <div className="w-full phone:pt-6 tablet:pt-6 desktop:pt-0">
            <p className="text-xl text-stone-700 text-semibold font-thin font-display underline pb-2">
              Recent Resource Links
            </p>
          </div>
          <div className="w-3/5 phone:grid tablet:grid phone:grid-cols-2 grid-cols-4 gap-2 flex flex-col divide-y divide-gray-200">
            {data.allGraphCmsResource.edges.map(featuredResource => (
              <div className="w-full first-of-type:mt-0 mb-4 last-of-type:mb-0">
                <div className="w-full flex flex-row">
                  <div className="w-1/3 order-2">
                    <GatsbyImage
                      image={featuredResource.node.featuredImage.gatsbyImageData}
                      className="phone:w-full tablet:w-full phone:h-32 tablet:h-32 h-12 w-12"
                    />
                  </div>
                  <div className="phone:w-full w-2/3 phone:flex-none tablet:flex-none flex justify-center flex-col pr-8">
                    <div className="text-lg phone:text-center tablet:text-center text-left text-stone-700 hover:text-blue-500">
                      <Link
                        to={`/resources/${featuredResource.node.slug}`}
                        key={featuredResource.node.id}
                      >
                        {featuredResource.node.title}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    )}
  ></StaticQuery>
)

export default FeaturedResources
