import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { JsonLd } from "../components/JsonLd"
import Moment from "react-moment"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import Directory from "../components/directory/directory"
import RecentArticles from "../components/recentArticles"
import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  RedditShareButton,
  RedditIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share"

const Post = ({ post, meta }) => {
  return (
    <>
      <Helmet
        title={post.title}
        titleTemplate={`%s | ${meta.title}`}
        meta={[
          {
            property: "og:type",
            content: "article",
          },
          {
            name: "description",
            content: `${post.description}`,
          },
          {
            property: "og:url",
            content: `${meta.url}/articles/${post.category.slug}/${post.slug}`,
          },
          {
            property: "og:title",
            content: `${post.title} | TIVP`,
          },
          {
            property: "og:description",
            content: `${post.description}`,
          },
          {
            property: "og:image:secure_url",
            content: `${post.featuredImage.url}`,
          },
          {
            property: "og:image",
            content: `${post.featuredImage.url}`,
          },
          {
            property: "og:image:width",
            content: `${post.featuredImage.width}`,
          },
          {
            property: "og:image:height",
            content: `${post.featuredImage.height}`,
          },
          {
            property: "article:author",
            content: `${post.author.name}`,
          },
          {
            name: "twitter:card",
            content: "summary_large_image",
          },
          {
            name: "twitter:creator",
            content: "@tivp_org",
          },
          {
            name: "twitter:site",
            content: "@tivp_org",
          },
          {
            name: "twitter:title",
            content: `${post.title} | TIVP`,
          },
          {
            name: "twitter:description",
            content: `${post.description}`,
          },
          {
            name: "twitter:image",
            content: `${post.featuredImage.url}`,
          },
        ].concat(meta)}
      >
        <html lang="en" />
        <JsonLd>
          {{
            "@context": "http://schema.org/",
            "@type": "Article",
            author: {
              "@type": "Person",
              name: `${post.author.name}`,
              headline: `${post.title}`,
              image: `${post.featuredImage.url}`,
              datePublished: `${post.date}`,
              dateModified: `${post.updatedAt}`,
            },
            publisher: {
              "@type": "Organization",
              name: `${meta.organization.name}`,
              logo: {
                "@type": "ImageObject",
                url: `${meta.logo}`,
              },
            },
          }}
        </JsonLd>
      </Helmet>
      <Layout>
        <div className="w-full laptop:flex-row px-4 desktop:px-0 bg-white phone:py-12 py-24">
          <div className="flex justify-center content-center">
            <div className="w-full laptop:w-3/5 desktop:w-auto 4k:w-1/4 flex justify-center prose prose-li:list-none">
              <div className="w-full phone:px-4 px-8">
                <div className="w-full flex flex-col">
                  <div className="text-sm text-stone-400 font-sans tracking-wide text-center desktop:text-left">{post.category.title}</div>
                  <div className="uppercase text-4xl text-stone-700 text-center desktop:text-left font-oswald font-semibold mb-2">{post.title}</div>
                  <div className="w-full flex flex-row phone:justify-center tablet:justify-center justify-start items-center text-stone-400 font-quattro desktop:text-sm phone:gap-6 tablet:gap-6">
                    <div className="w-auto flex phone:justify-end justify-start items-center flex-row">
                      <span>by:</span>
                      <span className="pl-1">{post.author.name}</span>
                    </div>
                    <div className="w-auto flex phone:justify-center justify-start items-center flex-row phone:mx-0 mx-2">
                      <div className="desktop:ml-2">
                        <span className="pr-1">published:</span>
                        <Moment format="MMMM D, YYYY">{post.date}</Moment>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="w-full flex justify-center">
                  <div className="w-full flex flex-col">
                    <div className="w-full h-auto flex justify-start phone:mt-2 phone:my-4 mt-4 mb-8">
                      <div className="h-full w-full border border-gray-400">
                        <Img
                          fluid={
                            post.featuredImage.localFile.childImageSharp.fluid
                          }
                          className="aspect-w-16 aspect-h-9 shadow-md shadow-gray-800/20"
                          alt={post.title}
                        />
                      </div>
                    </div>
                    <div className="w-full flex flex-col desktop:flex-row">
                      <div className="w-full py-2 desktop:py-6 desktop:pt-0">
                        <article
                          className="prose 4k:text-lg text-stone-600 font-roboto prose-green max-w-none selection:bg-green-200 pb-6 prose-blockquote:p-2 prose-blockquote:text-lg prose-blockquote:font-oswald prose-blockquote:tracking-wide"
                          dangerouslySetInnerHTML={{
                            __html: post.content.html,
                          }}
                        />
                      </div>
                    </div>
                    <div className="w-full phone:flex tablet:flex flex-col hidden print:hidden">
                      <div className="w-full flex flex-row justify-center items-center py-6 border-t border-gray-300">
                        <TwitterShareButton
                          title={`${post.title} | The Infinite Voice Project`}
                          caption={post.description}
                          url={`https://theinfinitevoiceproject.org/articles/${post.category.slug}/${post.slug}`}
                        >
                        <TwitterIcon size={48} round={false} />
                        </TwitterShareButton>
                        <FacebookShareButton
                          quote={post.description}
                          url={`https://theinfinitevoiceproject.org/articles/${post.category.slug}/${post.slug}`}
                        >
                        <FacebookIcon size={48} round={false} />
                        </FacebookShareButton>
                        <RedditShareButton
                          title={`${post.title} | The Infinite Voice Project`}
                          url={`https://theinfinitevoiceproject.org/articles/${post.category.slug}/${post.slug}`}
                        >
                        <RedditIcon size={48} round={false} />
                        </RedditShareButton>
                        <EmailShareButton
                          subject={`${post.title} | The Infinite Voice Project`}
                          body={post.description}
                          url={`https://theinfinitevoiceproject.org/articles/${post.category.slug}/${post.slug}`}
                        >
                        <EmailIcon size={48} round={false} />
                        </EmailShareButton>
                        <WhatsappShareButton
                          title={`${post.title} | The Infinite Voice Project`}
                          url={`https://theinfinitevoiceproject.org/articles/${post.category.slug}/${post.slug}`}
                        >
                        <WhatsappIcon size={48} round={false} />
                        </WhatsappShareButton>
                      </div>
                    </div>
                    <div className="w-full phone:flex tablet:flex flex-col desktop:pt-0 border-t border-gray-300 hidden print:hidden">
                      <RecentArticles />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*<div className="w-1/5 flex phone:hidden tablet:hidden">
            <RecentArticles />
            </div>
            */}
          </div>
        </div>
        <Directory />
      </Layout>
    </>
  )
}

const Article = ({ data }) => {
  const postContent = data.graphCmsPost
  const meta = data.site.siteMetadata
  if (!postContent) return null
  return <Post post={postContent} meta={meta} />
}

export default Article

Post.propTypes = {
  post: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
}

export const query = graphql`
  query PostTenplateQuery($id: String!) {
    graphCmsPost(id: { eq: $id }) {
      title
      id
      slug
      date
      description
      updatedAt
      content {
        html
        text
      }
      category {
        title
        slug
      }
      author {
        name
        id
      }
      featuredImage {
        url
        width
        height
        localFile {
          childImageSharp {
            gatsbyImageData
            fluid {
              srcSetWebp
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        title
        description
        author
        url
        logo
        organization {
          name
        }
      }
    }
  }
`
